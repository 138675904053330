// Efeito do menu
$('.clique-menu').hover(function(){
	$('.menu-hover').stop(true, true).slideToggle();
});

$('.clique-menu-mobile').click(function(){
  $('.menu-hover').stop(true, true).slideToggle();
});

$('.clique-pointer').hover(function(){
  $('.hover-pointer').stop(true, true).slideToggle();
});

$('.clique-pointer-mobile').click(function(){
  $('.hover-pointer').stop(true, true).slideToggle();
});

// Slide

$('.slider').slick({
  dots: true,
  infinite: true,
  speed: 700,
  arrows: false,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
});


// Slider Servicos


 $('.slide-servico').slick({
  slidesToShow: 3,
  slidesToScroll: 3,
  dots: true,
  arrows: false,
  autoplay: false,
  focusOnSelect: true,
   responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        autoplay: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
         dots: true
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
         dots: true
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});

// Slider Corpo Clínico

 $('.slider-corpo').slick({
  slidesToShow: 4,
  slidesToScroll: 4,
  dots: true,
  arrows: true,
  autoplay: false,
  focusOnSelect: true,
   prevArrow: $('.seta-direita'),
   nextArrow: $('.seta-esquerda'),
   responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        autoplay: true,
        dots: true,
        arrows: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
         dots: true,
         arrows: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
         dots: true,
         arrows: false
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});


 // Efeito Abas

 $('.aba-strava').click(function(){
    $('.box-strava').stop(true, true).fadeIn();
    $('.box-facebook').stop(true, true).hide();
    $('.box-instagram').stop(true, true).hide();
 });

 $('.aba-facebook').click(function(){
    $('.box-facebook').stop(true, true).fadeIn();
    $('.box-strava').stop(true, true).hide();
    $('.box-instagram').stop(true, true).hide();
 });

 $('.aba-instagram').click(function(){
    $('.box-instagram').stop(true, true).fadeIn();
    $('.box-facebook').stop(true, true).hide();
    $('.box-strava').stop(true, true).hide();
 });

 // IMC

$('.clique-imc').click(function(){
  $(this).hide();
  $('.mudar-display').addClass('load-imc');
  $('.caixa-do-imc').fadeIn(function(){
    if( $(this).css('display') == 'block'){
     $('.caixa-do-imc').addClass('caixa-imc-inline');
    } else{
      $('.caixa-do-imc').removeClass('caixa-imc-inline');
    }
  });
});

$('.calcular').click(function(){
  $('.imc').hide();
  $('.resultado-form').fadeIn();
});


$('.novo-teste').click(function(){
  $('.imc').fadeIn();
  $('.resultado-form').hide();
});


var waypoint = new Waypoint({
  element: document.getElementById('no-menu'),
  handler: function() {
   $('.box-menu-pointer').fadeOut();
  }
});

var waypoint = new Waypoint({
  element: document.getElementById('menu-interno'),
  handler: function() {
   $('.box-menu-pointer').fadeIn();
  }
});


